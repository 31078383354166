import React, { useState } from "react"
import ThankyouAnimation from "../../assets/images/popup-anim.gif"
import PopupClose from "../../assets/images/popup-close.svg"
import Slide from "../utility/slide_content"

function CTAThankYou() {
  const [visibilityHidden, setVisibilityHidden] = useState(true)

  const handlePopupClose = () => {
    setVisibilityHidden(!visibilityHidden)
  }

  return (
    <>
      <div
        id="cta_thank_you_wrap"
        className={`remove-popup ${visibilityHidden ? "active" : "closed"}`}
      ></div>
      <div
        id="cta_thank_you_parent"
        className={`remove-popup ${visibilityHidden ? "active" : "closed"}`}
      >
        <div className="cta_thank_you">
          <span
            onClick={handlePopupClose}
            onKeyDown={handlePopupClose}
            role="button"
            tabIndex={0}
          >
            <img
              src={PopupClose}
              className="close objcvr"
              loading="lazy"
              alt="close"
              width="8"
              height="8"
            />
          </span>
          <img
            src={ThankyouAnimation}
            loading="lazy"
            alt="Thank You"
            className="animation objcvr"
            width="100"
            height="100"
          />
          <Slide delay="200">
            <h2> Thanks for joining our pilot program! We're super stoked! </h2>
          </Slide>
          <Slide delay="250">
            <p>
              {" "}
              One of us will get in touch with you in the next 24 hours to talk
              about the next steps.{" "}
            </p>
          </Slide>
        </div>
      </div>
    </>
  )
}

export default CTAThankYou
