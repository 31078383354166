import axios from "axios"
import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import "rc-slider/assets/index.css"
import React, { useEffect, useState } from "react"
import AnimatedNumber from "react-animated-number"
import { Button, ButtonGroup, Col, Container, Row } from "react-bootstrap"
import CustomAccordion from "../../components/common/accordian"
import Buttons from "../../components/common/button"
import CTA from "../../components/common/CTA"
import Frame from "../../components/common/frame"
import SVGIcon from "../../components/common/SVGIcon"
import Navigation from "../../components/navigation"
import Plans from "../../components/pricing/plans"
import CTAThankYou from "../../components/utility/cta_thank_you"
import Slide from "../../components/utility/slide_content"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pricing.scss"

function Pricing() {
  const [isMonthly, setIsMonthly] = useState(false)

  const [ep, setEP] = useState(1)
  const [epPack, setEPPack] = useState(150)
  const [epCost, setEPCost] = useState(75)
  const [epCostStatic, setEPCostStatic] = useState(75)

  const [currency, setCurrency] = useState("USD")
  const [currencySymbol, setCurrencySymbol] = useState("$")

  useEffect(() => {
    const intercomShow = () => {
      if (typeof window.Intercom !== "undefined") {
        window.Intercom("show")
      }
    }

    document.getElementById("chatId2").onclick = function () {
      intercomShow()
    }

    document.querySelectorAll(
      ".faq .outer .accordion .card-body p code"
    )[0].onclick = function (e) {
      intercomShow()
    }

    document.querySelectorAll(
      ".faq .outer .accordion .card-body p code"
    )[1].onclick = function (e) {
      intercomShow()
    }

    document.querySelectorAll(
      ".faq .outer .accordion .card-body p code"
    )[2].onclick = function (e) {
      intercomShow()
    }
  })

  useEffect(() => {
    axios.get(process.env.IP_STACK_API).then(function (response) {
      if (
        response.data.continent_name === "Europe" &&
        response.data.country_name !== "United Kingdom"
      ) {
        setCurrency("EURO")
        setEPCostStatic(65)
        setCurrencySymbol("€")
      } else if (response.data.country_name === "United Kingdom") {
        setCurrency("GBP")
        setEPCostStatic(55)
        setCurrencySymbol("£")
      }
    })
  })

  useEffect(() => {
    setEPCost(ep * epCostStatic)
    setEPPack(ep * 150)
  }, [ep, epCostStatic])

  const changeEPValue = value => {
    if (value === "+") {
      setEP(ep + 1)
    } else {
      if (ep > 1) {
        setEP(ep - 1)
      }
    }
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentPricing {
            SuperOps {
              pages(where: { title: "Pricing" }) {
                title
                pageBlock {
                  id
                  content {
                    html
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                ctaBoxes {
                  type
                  theme
                  heading {
                    html
                  }
                  description {
                    html
                  }
                  primaryButtonText
                  primaryButtonLink
                  secondaryButtonText
                  secondaryButtonLink
                }
              }
              faqs(where: { page: "Pricing" }) {
                category
                question
                answerrt {
                  html
                }
              }
            }
          }
        `}
        render={data => (
          <div className="pricing-wrap">
            {data.SuperOps.pages.map(page => {
              const { pageBlock, navigationBlock, seo, ctaBoxes } = page
              return (
                <>
                  <Frame seo={seo}>
                    <header>
                      <Navigation
                        links={navigationBlock}
                        page="About us"
                        color={`#F1F8FF`}
                      />
                    </header>

                    <section className="main">
                      <div
                        className="hero prelative"
                        style={{ background: "#F1F8FF" }}
                      >
                        <Container className="pricing-hero-container text-center">
                          {parse(pageBlock[0].content.html)}

                          <span className="p18">
                            {parse(pageBlock[1].content.html)}
                          </span>

                          {/* TOGGLE */}
                          <div className=" toggle-wrap mx-auto">
                            <div class="switch-button">
                              <div
                                class={`switch-button-checkbox ${isMonthly ? "monthly-plan" : "yearly-plan"
                                  }`}
                              >
                                {" "}
                              </div>

                              <div class="switch-button-label" for="">
                                <span
                                  class="switch-button-label-1"
                                  onClick={() => setIsMonthly(true)}
                                  style={{
                                    color: isMonthly ? "#ffff" : "#170426",
                                  }}
                                >
                                  MONTHLY
                                  {/* {parse(pageBlock[15].content.html)} */}
                                </span>
                                <span
                                  class="switch-button-label-2"
                                  onClick={() => setIsMonthly(false)}
                                  style={{
                                    marginLeft: "0px",
                                    color: !isMonthly ? "#ffff" : "#170426",
                                  }}
                                >
                                  ANNUALLY
                                  {/* {parse(pageBlock[16].content.html)} */}
                                </span>
                              </div>
                            </div>
                            <div
                              className={`toggle-tag ${isMonthly ? "monthly-plan" : ""
                                }`}
                            >
                              <span>{parse(pageBlock[17].content.html)}</span>
                            </div>
                          </div>
                          {/* TOGGLE ENDS */}
                        </Container>
                      </div>

                      <div className="prelative">
                        <Container>
                          <Plans
                            isMonthly={isMonthly}
                            currency={currency}
                            currencySymbol={currencySymbol}
                          />
                        </Container>
                      </div>

                      <div className="extra">
                        <Container>
                          <center>
                            <Slide delay="200">
                              <div className="content prelative">
                                {/* <img
                                  src={SpiralBlackHT}
                                  className="SpiralBlackHT position-absolute"
                                  alt="img"
                                /> */}
                                <SVGIcon name="pricing/spiral-black-ht" className="SpiralBlackHT position-absolute" />
                                {/* <img
                                  src={SpiralBlackHB}
                                  className="SpiralBlackHB position-absolute"
                                  alt="img"
                                /> */}
                                <SVGIcon name="pricing/spiral-black-hb" className="SpiralBlackHB position-absolute" />
                                {/* <img
                                  src={DataGreen}
                                  className="DataGreen position-absolute"
                                  alt="img"
                                /> */}
                                <SVGIcon name="pricing/data-green" className="DataGreen position-absolute" />
                                {/* <img
                                  src={HarddiscYellow}
                                  className="HarddiscYellow position-absolute"
                                  alt="img"
                                /> */}
                                <SVGIcon name="pricing/harddisk-yellow" className="HarddiscYellow position-absolute" />
                                <Row className="extra-points-pricing">
                                  <Col lg={6} className="lft">
                                    {" "}
                                    {parse(pageBlock[12].content.html)}{" "}
                                  </Col>
                                  <Col lg={6} className="ryt">
                                    {" "}
                                    <div className="cover">
                                      <Row className="extra-points-pricing-split">
                                        <Col
                                          lg={6}
                                          className="lft d-flex flex-column align-items-center"
                                        >
                                          {" "}
                                          <span className="p14">
                                            {parse(pageBlock[13].content.html)}{" "}
                                          </span>
                                          <div className="button-group">
                                            <ButtonGroup className="btn-grp">
                                              <Button
                                                className="lf"
                                                onClick={() =>
                                                  changeEPValue("-")
                                                }
                                              >
                                                -
                                              </Button>
                                              <Button className="middle">
                                                {ep}
                                              </Button>
                                              <Button
                                                className="rt"
                                                onClick={() =>
                                                  changeEPValue("+")
                                                }
                                              >
                                                +
                                              </Button>
                                            </ButtonGroup>
                                            <div className="cost-inline p13">
                                              {" "}
                                              <p>
                                                x {epCostStatic}
                                                {currencySymbol}{" "}
                                              </p>{" "}
                                            </div>
                                          </div>
                                          <div className="cost-block">
                                            {" "}
                                            1 pack = 150 endpoints{" "}
                                          </div>
                                        </Col>
                                        <Col lg={6} className="ryt">
                                          <div className="outer prelative">
                                            <h2>
                                              {" "}
                                              {currencySymbol}
                                              <AnimatedNumber
                                                stepPrecision={0}
                                                value={epCost}
                                              />{" "}
                                            </h2>

                                            <span className="p12">
                                              <p> manage {epPack} endpoints </p>
                                            </span>
                                            <div className="mt60 mx-auto text-center">
                                              <Buttons
                                                theme="primary"
                                                link="/signup"
                                                text="GET STARTED FOR FREE"
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>{" "}
                                  </Col>
                                </Row>
                              </div>
                            </Slide>
                          </center>
                        </Container>
                      </div>

                      <div className="faq">
                        <center>
                          <section className="outer">
                            <Container>
                              <Slide delay="200">
                                {parse(pageBlock[9].content.html)}
                              </Slide>
                              <Slide delay="200">
                                {parse(pageBlock[10].content.html)}
                              </Slide>
                              <Slide delay="200">
                                {/* <FAQ /> */}
                                <CustomAccordion items={data.SuperOps.faqs} />
                              </Slide>

                              <Slide delay="200">
                                <Row>
                                  <Col lg={5}>
                                    <h5 className="pricing-faq-title">
                                      What if I have more questions?
                                    </h5>
                                  </Col>
                                  <Col lg={7}>
                                    <span className="p16">
                                      <p className="pricing-faq-desc">
                                        We're available round the clock to
                                        answer your questions and help you find
                                        the right plan.{" "}
                                        <span id="chatId2">Ping us</span> or
                                        drop us a line at sales@superops.com.
                                      </p>
                                    </span>
                                  </Col>
                                </Row>
                              </Slide>
                            </Container>
                          </section>
                        </center>
                      </div>

                      <div className="pricing-cta ">
                        <Slide delay="200">
                          <CTA data={ctaBoxes} />
                        </Slide>
                      </div>

                      {/* <div className="believe">
                      <center>
                        <section className="outer">
                          <Container>
                            <Slide delay="200">
                              {parse(pageBlock[11].content.html)}
                            </Slide>
                            <Slide delay="200">
                              <div className="inner">
                                <center>
                                  <section className="sp-reason bel-reason">
                                    <div className="content">
                                      <div className="column-container">
                                        <BelieveBlock />
                                      </div>
                                    </div>
                                  </section>
                                </center>
                              </div>
                            </Slide>
                          </Container>
                        </section>
                      </center>
                    </div> */}
                    </section>
                    <CTAThankYou />
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default Pricing
