import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { SlideDown } from "react-slidedown"
import "react-slidedown/lib/slidedown.css"
import DropIcon from "../../assets/images/dropdown-icon.svg"
import PricingOdometer from "../../components/pricing/pricing-odometer"
import "../../styles/component/plans.scss"
import Buttons from "../common/button"



function Plans(props) {
  const [currentPlan, setCurrentPlan] = useState(1000)

  const handleCurrentPlan = index => {
    if (index === currentPlan) {
      setCurrentPlan(1000)
    } else {
      setCurrentPlan(index)
    }
  }

  const popularTag = value => {
    if (value === "Yes") {
      return <div className="tag">MOST POPULAR</div>
    } else {
      return <div className="tag-empty"></div>
    }
  }

  const currentItem = (isMonthly, month, annual) => {
    return isMonthly ? month : annual
  }

  const setCurrencyValue = (
    isMonthly,
    currency,
    m,
    a,
    eurom,
    euroa,
    gbpm,
    gbpa
  ) => {
    if (isMonthly && currency === "USD") {
      return m
    }

    if (!isMonthly && currency === "USD") {
      return a
    }

    if (isMonthly && currency === "EURO") {
      return eurom
    }
    if (!isMonthly && currency === "EURO") {
      return euroa
    }
    if (isMonthly && currency === "GBP") {
      return gbpm
    }
    if (!isMonthly && currency === "GBP") {
      return gbpa
    }
  }

  return (
    <div className="plans">
      <StaticQuery
        query={graphql`
          query pricingList {
            SuperOps {
              pricingLists {
                page
                planName
                features
                monthlyPrice
                monthlyPriceDescription
                annualPrice
                annualPriceDescription
                endPoints
                isPopular
                description
                euroMonthlyPrice
                euroAnnualPrice
                gbpMonthlyPrice
                gbpAnnualPrice
                featureLists {
                  id
                  name
                  parentName
                  parentSlug
                  features
                  featuresSlug
                  featuresDescription
                }
              }
            }
          }
        `}
        render={data => (
          <Row className="justify-content-center">
            {data.SuperOps.pricingLists.filter(list => list.page != 'pricingnew').map((pl, plin) => {
              const {
                planName,
                features,
                monthlyPrice,
                monthlyPriceDescription,
                annualPrice,
                annualPriceDescription,
                endPoints,
                featureLists,
                isPopular,
                description,
                euroMonthlyPrice,
                euroAnnualPrice,
                gbpMonthlyPrice,
                gbpAnnualPrice,
              } = pl

              return (
                <>
                  <Col md={6} lg={3} className="wrap">
                    <center
                      style={{ marginTop: isPopular === "Yes" ? "-27px" : "" }}
                    >
                      {popularTag(isPopular)}
                    </center>

                    <div
                      className={`content position-relative ${isPopular ===
                        "Yes" && "popular"}`}
                    >
                      <div>
                        <h5 className="name"> {planName} </h5>
                        <p className="excerpt"> {features} </p>
                        <div className="pricing-info-wrap"> {description} </div>
                        <div className="price-wrap">
                          <div className="left">
                            <p className="top">{props.currencySymbol}</p>
                            <p className="bottom">{props.currency}</p>
                          </div>
                          <div className="right">
                            <PricingOdometer
                              price={setCurrencyValue(
                                props.isMonthly,
                                props.currency,
                                monthlyPrice,
                                annualPrice,
                                euroMonthlyPrice,
                                euroAnnualPrice,
                                gbpMonthlyPrice,
                                gbpAnnualPrice
                              )}
                            />
                          </div>
                        </div>
                        <div
                          className={`details p14 ${planName === "solo" &&
                            "price-solo-details"}`}
                        >
                          <p>
                            {currentItem(
                              props.isMonthly,
                              monthlyPriceDescription,
                              annualPriceDescription
                            )}
                          </p>
                        </div>
                        <div className="points p14">
                          <p className="sp-o">
                            Endpoints:{" "}
                            {!!endPoints && <span>{endPoints}/tech </span>}{" "}
                          </p>
                        </div>
                        <div>
                          <div className="line"> </div>
                        </div>
                        <div className="pricing-beta-btn">
                          <Buttons
                            link="/signup"
                            theme={`${isPopular === "Yes"
                              ? "primary md w-100"
                              : "secondary md w-100"
                              }  mt40`}
                            text="GET STARTED FOR FREE"
                          />
                        </div>
                      </div>

                      <div className="pricing-feature-dsk">
                        {featureLists.map(fl => {
                          return (
                            <div className="pricing-plans-content-wrap">
                              <p className="feature-parent">{fl.parentName}</p>

                              {fl.features.map((f, i) => {
                                return <p className="feature">{f}</p>
                              })}
                            </div>
                          )
                        })}

                        {planName === "solo" ? (
                          <div className="pricing-solo-link">
                            <a href="/pricing/free-psa-rmm-solo-plan">
                              learn more about Solo plan
                            </a>
                          </div>
                        ) : null}
                      </div>

                      <div className="pricing-feature-mob">
                        <SlideDown>
                          {plin === currentPlan ? (
                            <>
                              {featureLists.map((fl, flin) => {
                                return (
                                  <div className="p16">
                                    <p className="feature-parent">
                                      {fl.parentName}
                                    </p>

                                    {fl.features.map((f, i) => {
                                      return (
                                        <div className="p14">
                                          <p className="feature">{f}</p>
                                        </div>
                                      )
                                    })}
                                  </div>
                                )
                              })}
                              <div
                                style={{
                                  display: planName !== "solo" && "none",
                                }}
                                className="pricing-solo-link"
                              >
                                <a href="/pricing/solo-plan">
                                  learn more about Solo plan
                                </a>
                              </div>
                            </>
                          ) : null}
                        </SlideDown>

                        <div
                          className={`pr-pl-btn ${plin === currentPlan &&
                            "active"}`}
                          onClick={() => handleCurrentPlan(plin)}
                          onKeyDown={() => handleCurrentPlan(plin)}
                          role="button"
                          tabIndex={0}
                        >
                          <center className="p14">
                            <p>
                              {plin === currentPlan ? (
                                <> view less </>
                              ) : (
                                <> view features </>
                              )}
                              <img src={DropIcon} alt="dropdown" />
                            </p>
                          </center>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              )
            })}
          </Row>
        )}
      />
    </div>
  )
}

export default Plans
