import React, { useState, useEffect } from "react"
import AnimatedNumber from "react-animated-number"

function PricingOdometer(props) {
  const [price, setPrice] = useState(Number(props.price))
  const [count, setCount] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      if (Number(price) < Number(props.price)) {
        setPrice(Number(price) + (Number(props.price) - Number(price)))
      }
      if (Number(price) > Number(props.price)) {
        setPrice(Number(price) - (Number(price) - Number(props.price)))
      }
    }, 100)
    setCount(count + 1)
  }, [props.price])

  return (
    <>
      {count > 1 ? <AnimatedNumber className="price" component="p" stepPrecision={0} value={price} /> : <p className="price">{price}</p>}
    </>
  )
}

export default PricingOdometer
